
.account-main {
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    .account-title {
        padding-top: 10px;
        width: 50px;
    }
    .account-list {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }
}
.progress-line {
    height: 2px;
    background: #594FEE;
    position: absolute;
    bottom: 0;
    left: 0;
}
.upload-icon {
    width: 100%;
    height: 100%;
    border: 1px dashed #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    i {
        font-size: 30px;
        color: #594FEE;
    }
    #cover {
        display: none;
    }
}
.image-cover {
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}
.video-management-content {
    background-color: #fff;
    padding-top: 35px;
    .video-management-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-family: "Microsoft YaHei",serif;
        font-weight: 400;
        margin: 0 35px;
        padding-bottom: 19px;
        .publish-video {
            background-color: #594FED;
            border-color: #594FED;
            border-radius: 15px;
            &:hover {
                background-color: #463bdc;
                border-color: #463bdc;
            }
        }
    }
    .select-content {
        ::v-deep .el-checkbox-group {
            display: flex;
            .img-item {
                .el-checkbox__label {
                    line-height: 10px;
                    img {
                        width: 38px;
                        height: 38px;
                    }
                }
            }
        }
        ::v-deep .el-checkbox {
            width: 63px !important;
            display: flex;
            justify-content: start;
            align-items: center;
        }
        padding: 0 35px 0 45px;
        .item {
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin: 20px 0 20px 0;
            color: #666;
        }
        .item-account {
            border-bottom: 1px solid #D1D2DB;
            padding-bottom: 20px;
            ::v-deep .el-checkbox-group {
                .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #9ACC5C;
                    border-color: #9ACC5C;
                    &:hover {
                        border-color: #9ACC5C;
                    }
                }
                .el-checkbox__inner {
                    &:hover {
                        border-color: #9ACC5C;
                    }
                }
            }
        }
        .sort-item {
            display: inline-block;
            padding: 2px 6px;
            margin: 0 5px;
        }
        .sort-item.current, .sort-item:hover {
            background: #9ACC5B;
            color: #fff;
            border-radius: 5px;
            cursor: pointer;
        }
        .img-item {
            background: transparent !important;
            width: 38px;
            height: 38px;
            border-radius: 50% !important;
            margin-right: 12px;
            margin-top: 12px;
            cursor: pointer;
            position: relative;
            img.type-img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            img.img-item-selected {
                position: absolute;
                display: none;
                bottom: -2px;
                right: -2px;
                width: 14px;
                height: 14px;
            }
        }
        .current {
            img.img-item-selected {
                display: block;
            }
        }
        .info-form {
            min-height: calc(100vh - 346px);
            padding-bottom: 10px;
            .add-video {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .up-img-show {
                    margin-right: 200px;
                    .video-text {
                        color: #BCB9CB;
                        margin-left: 20px;
                    }
                }
            }
            .video-request {
                display: flex;
                flex-direction: column;
                color: #BCB9CB;
                font-size: 14px;
                margin-left: 75px;
            }
            .video-cover {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 120px;
                height: 120px;
                background: #ECEBF9;
                border-radius: 10px;
                cursor: pointer;
                i {
                    color: #9ACC5B;
                    font-size: 22px;
                }
                .el-upload__text {
                    font-size: 12px;
                    color: #5F5C74;
                }
            }
            .video-show {
                margin: 0 0 10px 75px;
                width: 300px;
                .video-title-desc {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    i {
                        font-size: 20px;
                    }
                    span {
                        margin-left: 10px;
                        font-size: 14px;
                    }
                }
            }
            .cover-img {
                display: flex;
                align-items: center;
                .img {
                    width: 450px;
                    height: 300px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .video-title-input {
                width: 270px;
                margin-right: 10px;
                ::v-deep .el-input__inner {
                    width: 270px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 10px;
                }
            }
            .add-video-title {
                padding: 6px 12px;
                background-color: #BCB9CB;
                border-color: #BCB9CB;
                font-size: 12px;
                border-radius: 10px;
                &:hover {
                    background-color: #b1b0b7;
                    border-color: #b1b0b7;
                }
            }
            .publish-time {
                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                    border-radius: 10px;
                    margin-left: 10px;
                }
                ::v-deep .el-input__prefix {
                    left: 15px;
                }
            }
            .upload-btn-video {
                position: relative;
                padding: 7px 16px;
                border-radius: 15px;
                background: #594FEE;
                border-color: #594FEE;
                cursor: pointer;
                &:hover {
                    background: #2338E6;
                    border-color: #2338E6;
                }
                input {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
            .publish-btn {
                ::v-deep .el-button {
                    padding: 7px 30px;
                    border-radius: 15px;
                    cursor: pointer;
                }
                .el-button--primary {
                    background: #594FEE;
                    border-color: #594FEE;
                    &:hover {
                        background: #2338E6;
                        border-color: #2338E6;
                    }
                }
                .el-button--default {

                }
            }
        }
    }
}
